import { FC } from "react";

import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const CloseIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="87"
        height="87"
        viewBox="0 0 87 87"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1.5"
          y="1.5"
          width="84"
          height="84"
          rx="42"
          stroke="#164194"
          strokeWidth="3"
        />
        <path
          d="M31.75 29.75L57.25 55.25M31.75 55.25L57.25 29.75"
          stroke="#164194"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
export default CloseIcon;

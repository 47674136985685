"use client";
import { FC } from "react";

import Button from "@mui/material/Button";
import { alpha } from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

import { colorPrimary700, colorText50 } from "theme/colors";

type CheckButtonType = {
  url: string;
  text: string;
};

const CheckButton: FC<CheckButtonType> = ({ url, text }) => {
  return (
    <Button
      href={url}
      sx={{
        mt: 4,
        backgroundColor: colorPrimary700,
        color: colorText50,
        fontSize: { xs: "3.5vw", sm: "2vh" },
        "&:hover": {
          backgroundColor: alpha(colorPrimary700, 0.9),
        },
      }}
    >
      {text}{" "}
      <ArrowOutwardIcon
        sx={{ fontSize: { xs: "3.5vw", sm: "1.5vh" }, ml: "1vh", mb: "0.4vh" }}
      />
    </Button>
  );
};
export default CheckButton;

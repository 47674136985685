// A - installments
// B - discount

const blue = "#164194";
const yellow = "#FDD22F";
const green = "#22D3BD";

export const mockVariant = undefined;

export const popup = {
  A: {
    backgroundColor: blue,
    image: "https://zdrowegeny.pl/cmsstatic/testab/popup/lab-woman.webp",
    backgroundImg1: "/ab/popup/background.svg",
    backgroundImg2: "/ab/popup/background2.svg",
    imageMobile:
      "https://zdrowegeny.pl/cmsstatic/testab/slider/lab-woman-mobile.webp",
    backgroundImg1Mobile: "/ab/slider/background-mobile.svg",
    backgroundImg2Mobile: "/ab/slider/background2-mobile.png",
    circleColor1: yellow,
    circleColor2: green,
    textLine1: "TYLKO TERAZ",
    textLine2: "Raty 0%",
    textLine3: "na wszystkie badania",
    textLine4: "od 300 zł",
    buttonText: "Sprawdź",
    buttonURL: "/badania?od=300&do=10000",
    discountCode: undefined,
  },
  B: {
    backgroundColor: blue,
    image: "https://zdrowegeny.pl/cmsstatic/testab/popup/lab-woman.webp",
    backgroundImg1: "/ab/popup/background.svg",
    backgroundImg2: "/ab/popup/background2.svg",
    imageMobile:
      "https://zdrowegeny.pl/cmsstatic/testab/slider/lab-woman-mobile.webp",
    backgroundImg1Mobile: "/ab/slider/background-mobile.svg",
    backgroundImg2Mobile: "/ab/slider/background2-mobile.png",
    circleColor1: yellow,
    circleColor2: green,
    textLine1: "TYLKO TERAZ",
    textLine2: "Rabat 5%",
    textLine3: "na wszystkie badania",
    textLine4: "z kodem ",
    buttonText: "Sprawdź",
    buttonURL: "/badania",
    discountCode: "ZDROWIE5",
  },
};

export const slider = {
  A: {
    image: "https://zdrowegeny.pl/cmsstatic/testab/slider/lab-woman.webp",
    alt: "",
    backgroundImg1: "/ab/slider/background.svg",
    backgroundImg2: "/ab/slider/background2.svg",
    imageMobile:
      "https://zdrowegeny.pl/cmsstatic/testab/slider/lab-woman-mobile.webp",
    backgroundImg1Mobile: "/ab/slider/background-mobile.svg",
    backgroundImg2Mobile: "/ab/slider/background2-mobile.png",
    circleColor1: yellow,
    circleColor2: green,
    textLine1: "TYLKO TERAZ",
    textLine2: "Raty 0% na",
    textLine3: "wszystkie badania",
    textLine4: "od 300&nbsp;zł",
    buttonText: "Sprawdź",
    buttonURL: "/badania?od=300&do=10000",
    discountCode: undefined,
  },
  B: {
    image: "https://zdrowegeny.pl/cmsstatic/testab/slider/lab-woman.webp",
    alt: "",
    backgroundImg1: "/ab/slider/background.svg",
    backgroundImg2: "/ab/slider/background2.svg",
    imageMobile:
      "https://zdrowegeny.pl/cmsstatic/testab/slider/lab-woman-mobile.webp",
    backgroundImg1Mobile: "/ab/slider/background-mobile.svg",
    backgroundImg2Mobile: "/ab/slider/background2-mobile.png",
    circleColor1: yellow,
    circleColor2: green,
    textLine1: "TYLKO TERAZ",
    textLine2: "Rabat 5% na",
    textLine3: "wszystkie badania",
    textLine4: "z&nbsp;kodem: ",
    buttonText: "Sprawdź",
    buttonURL: "/badania",
    discountCode: "ZDROWIE5",
  },
};

export const banner = {
  A: {
    backgroundImg1: "/ab/banner/background.svg",
    backgroundImg2: "/ab/banner/background2.svg",
    circleColor1: yellow,
    circleColor2: green,
    textLine1: "TYLKO TERAZ: ",
    textLine2: "Raty 0% na wszystkie badania od 300 zł",
    discountCode: undefined,
  },
  B: {
    backgroundImg1: "/ab/banner/background.svg",
    backgroundImg2: "/ab/banner/background2.svg",
    circleColor1: yellow,
    circleColor2: green,
    textLine1: "TYLKO TERAZ: ",
    textLine2: "Rabat 5% na wszystkie badania z kodem: ",
    discountCode: "ZDROWIE5",
  },
};

export const tooltip = {
  A: {
    text: "TERAZ RATY 0%",
    textColor: blue,
    backgroundColor: yellow,
  },
  B: undefined,
};

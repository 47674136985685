"use client";

import { FC } from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import CloseIcon from "components/AbTestDiscountVsInstallments/popup/CloseIcon";
import CheckButton from "components/AbTestDiscountVsInstallments/CheckButton";

import { colorPrimary700, colorText50 } from "theme/colors";

type ABPopupDialogProps = {
  isOpen: boolean;
  handleOnClose: () => void;
  backgroundColor: string;
  image: string;
  backgroundImg1: string;
  backgroundImg2: string;
  imageMobile: string;
  backgroundImg1Mobile: string;
  backgroundImg2Mobile: string;
  textLine1: string;
  textLine2: string;
  textLine3: string;
  textLine4: string;
  buttonText: string;
  buttonURL: string;
  discountCode?: string;
};

const ABPopupDialog: FC<ABPopupDialogProps> = ({
  isOpen,
  handleOnClose,
  backgroundColor,
  textLine1,
  textLine2,
  image,
  backgroundImg2,
  backgroundImg1,
  buttonURL,
  discountCode,
  textLine3,
  textLine4,
  buttonText,
  imageMobile,
  backgroundImg1Mobile,
  backgroundImg2Mobile,
}) => {
  return (
    <Dialog
      open={isOpen}
      keepMounted
      onClose={handleOnClose}
      aria-describedby="alert-dialog-slide-description"
      disableScrollLock
      maxWidth={false}
      sx={{
        "& .MuiPaper-root": {
          boxShadow: "0px 0px 60px rgba(0, 0, 0, 0.5)",
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        },
      }}
      PaperProps={{
        sx: {
          padding: "0!important",
          backgroundColor: { backgroundColor },
          borderRadius: "25px",
          width: {
            xs: "100%",
            sm: "800px",
            md: "900px",
            lg: "1080px",
            xl: "75vw",
          },
          height: {
            xs: undefined,
            sm: "451px",
            md: "508px",
            lg: "610px",
            xl: "85vh",
          },
          aspectRatio: { xs: 1 / 1.2, sm: undefined },
          overflow: "none",
        },
      }}
    >
      <DialogContent
        sx={{ margin: 0, padding: 0, position: "relative", overflow: "hidden" }}
      >
        <Box
          sx={{
            display: { xs: "none", sm: "block" },
            zIndex: 1,
            position: "absolute",
            left: 0,
            top: 0,
            bottom: 0,
            backgroundImage: `url(${image})`,
            backgroundSize: { xs: "180%", sm: "contain" },
            backgroundPosition: { xs: "top left", sm: "left" },
            backgroundRepeat: "no-repeat",
            height: "100%",
            width: "100%",
          }}
        />
        <Box
          sx={{
            display: { xs: "none", sm: "block" },
            zIndex: 2,
            position: "absolute",
            right: { xs: "-60%", sm: 0 },
            top: 0,
            backgroundImage: `url(${backgroundImg1})`,
            backgroundSize: { xs: "contain", sm: "contain" },
            backgroundPosition: { xs: "right top", sm: "right" },
            backgroundRepeat: "no-repeat",
            height: "66%",
            width: "100%",
            overflow: "hidden",
          }}
        />
        <Box
          sx={{
            display: { xs: "block", sm: "none" },
            zIndex: 2,
            position: "absolute",
            bottom: "0",
            backgroundImage: `url(${backgroundImg2Mobile})`,
            backgroundSize: "contain",
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
            height: "100%",
            width: "100%",
          }}
        />
        <Box
          sx={{
            zIndex: { xs: 1, sm: 3 },
            position: "absolute",
            right: { xs: undefined, sm: 0 },
            bottom: 0,
            backgroundImage: {
              xs: `url(${backgroundImg1Mobile})`,
              sm: `url(${backgroundImg2})`,
            },
            backgroundSize: { xs: "cover", sm: "contain" },
            backgroundPosition: { xs: "top", sm: "right" },
            backgroundRepeat: "no-repeat",
            height: { xs: "70%", sm: "68%" },
            width: { xs: "200%", sm: "100%" },
          }}
        >
          <Stack
            direction="column"
            paddingTop={{ xs: 10, sm: 4, lg: 4, xl: 4 }}
            paddingRight={{ xs: 0, sm: 10, lg: 12, xl: 16 }}
            paddingBottom={{ xs: 10, sm: 10, lg: 12, xl: 16 }}
            paddingLeft={{ xs: 10, sm: 4, xl: 4 }}
            sx={{
              justifyContent: { xs: "flex-start", sm: "flex-end" },
              alignItems: { xs: "flex-start", sm: "flex-end" },
              height: "100%",
            }}
          >
            <Typography
              color={colorText50}
              sx={{
                fontWeight: 700,
                letterSpacing: "-2px",
                fontSize: {
                  xs: "4.5vw",
                  sm: "1.5rem",
                  md: "1.75rem",
                  lg: "2rem",
                  xl: "4vh",
                },
              }}
            >
              {textLine1}
            </Typography>
            <Typography
              sx={{
                lineHeight: "1.2",
                fontWeight: 700,
                fontSize: {
                  xs: "11vw",
                  sm: "3rem",
                  md: "3.5rem",
                  lg: "4rem",
                  xl: "10vh",
                },
              }}
              color={colorPrimary700}
            >
              {textLine2}
            </Typography>
            <Typography
              sx={{
                lineHeight: "1.2",
                letterSpacing: "-1.5px",
                fontWeight: 700,
                fontSize: {
                  xs: "5.5vw",
                  sm: "1.5rem",
                  md: "1.75rem",
                  lg: "2rem",
                  xl: "5vh",
                },
                whiteSpace: "nowrap",
              }}
              color={colorPrimary700}
            >
              {textLine3}
            </Typography>
            <Typography
              sx={{
                lineHeight: "1.2",
                letterSpacing: "-1.5px",
                fontWeight: 700,
                fontSize: {
                  xs: "5.5vw",
                  sm: "1.5rem",
                  md: "1.75rem",
                  lg: "2rem",
                  xl: "5vh",
                },
              }}
              color={colorPrimary700}
            >
              {textLine4}
              {discountCode && (
                <Typography
                  component={"span"}
                  sx={{
                    lineHeight: "1.2",
                    fontWeight: 700,
                    fontSize: {
                      xs: "5.5vw",
                      sm: "1.5rem",
                      md: "1.75rem",
                      lg: "2rem",
                      xl: "5vh",
                    },
                  }}
                  color={colorText50}
                >
                  {discountCode}
                </Typography>
              )}
            </Typography>
            <CheckButton url={buttonURL} text={buttonText} />
          </Stack>
        </Box>
        <Box
          sx={{
            display: { xs: "block", sm: "none" },
            zIndex: 0,
            position: "absolute",
            right: 0,
            top: 0,
            backgroundImage: `url(${imageMobile})`,
            backgroundSize: "contain",
            backgroundPosition: "top",
            backgroundRepeat: "no-repeat",
            height: "100%",
            width: "100%",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: { xs: 4, sm: 12 },
            right: { xs: 4, sm: 12 },
            zIndex: 4,
          }}
        >
          <IconButton onClick={handleOnClose} aria-label="Zamknij banner">
            <CloseIcon sx={{ fontSize: { xs: 38, sm: 48 } }} />
          </IconButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default ABPopupDialog;

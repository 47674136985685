"use client";
import { FC, useEffect, useState } from "react";

import ABPopupDialog from "components/AbTestDiscountVsInstallments/popup/ABPopupDialog";
import {
  mockVariant,
  popup,
} from "components/AbTestDiscountVsInstallments/data";

type ABPopupProps = {
  variantAB: string;
};

const popupMap = {
  A: popup.A,
  B: popup.B,
};

const ABPopup: FC<ABPopupProps> = ({ variantAB }) => {
  const variant = mockVariant ? mockVariant : variantAB;
  const data = popupMap[variant] || undefined;
  const [isPopupToDisplay, setIsPopupToDisplay] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      const zgPopup = sessionStorage.getItem("zg-popup");

      if (!zgPopup) {
        setIsPopupToDisplay(true);
        sessionStorage.setItem("zg-popup", "displayed");
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return isPopupToDisplay && data ? (
    <>
      <ABPopupDialog
        isOpen={isPopupToDisplay && data}
        handleOnClose={() => setIsPopupToDisplay(false)}
        {...data}
      />
    </>
  ) : (
    <></>
  );
};
export default ABPopup;

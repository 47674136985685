"use client";
import { FC, useEffect } from "react";

import { sendGTMEvent } from "@next/third-parties/google";

type ABTestEventType = {
  abTest: abTestType;
};

const ABTestEvent: FC<ABTestEventType> = ({ abTest }) => {
  const { nameTest, variantName, variant, forcedVariant } = abTest;

  useEffect(() => {
    const objToSend = {
      event: "AB_TestEvent",
      AB_testName: nameTest,
      AB_variant: `${variant} - ${variantName}`,
    };

    sendGTMEvent(objToSend);

    if (forcedVariant) {
      const objToSendExclude = {
        event: "AB_TestEvent",
        AB_testName: nameTest,
        AB_variant: `exclude`,
      };

      sendGTMEvent(objToSendExclude);
    }
  }, [nameTest, variantName, variant, forcedVariant]);

  return <></>;
};
export default ABTestEvent;
